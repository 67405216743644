<template>
  <div class="">
    <!-- <div class="media__content--box mp4 column" v-if="whichTab == 'Podcasts' || whichTab == 'Sales Video' || whichTab == 'Student Orientation Videos' || whichTab == 'Videos'" v-for="(data, i) in sortedCategories" :key="i">
      <h3>{{data.title}}</h3>
      <video rel="prefetch" :src="data.path" controls :poster="data.thumbnail"></video>
    </div>
    <div class="media__content--box pdf column" v-if="whichTab == 'Brochures' || whichTab == 'Case Study Form' || whichTab == 'Posters' || whichTab == 'Study Guides' || whichTab == 'Success Story Form' || whichTab == 'Teacher Guides' || whichTab == 'White Papers' || whichTab == 'Workbooks '" v-for="(data, i) in sortedCategories" :key="i">
      <a :href="data.path" target="_blank">
        <h3>{{data.title}}</h3>
        <img rel="prefetch" :src="data.thumbnail"></img>
      </a>
    </div> -->

    <div class="media__content--list text-center" v-if="toggleList">
      No data
    </div>
    <div class="mt-8 md:w-4/12 mx-auto" v-if="!toggleList">
      <ul
        class="flex flex-wrap flex-col items-left justify-between"
        v-for="(data, i) in sortedCategories"
        :key="i"
      >
        <li
          class="py-1 px-2 mb-1 text-secondary text-left underline cursor-pointer"
        >
          <a :href="data.path" target="_blank">
            <!-- <div>
              <img rel="prefetch" :src="data.thumbnail"></img>
            </div> -->
            <h3>{{ data.title }}</h3>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["mediaGeneral", "whichTab"],
  data() {
    return {
      sortedCategories: null,
      toggleList: false,
    };
  },
  methods: {
    searchCategories(nameKey, myArray) {
      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i].category === nameKey) {
          this.sortedCategories = myArray[i].data_category[0].media_files;
        }
      }
    },
    onChangeMedia(event) {
      this.$emit("changed", "");
    },
  },
  watch: {
    whichTab(val) {
      // console.log(val);
      if (val) {
        this.searchCategories(this.whichTab, this.mediaGeneral);
      } else {
        return false;
      }
    },
    mediaGeneral(val) {
      // console.log(val);
      if (val.length == 0) {
        this.toggleList = true;
        this.sortedCategories = null;
      } else {
        this.toggleList = false;
        this.sortedCategories = null;
        this.onChangeMedia();
      }
    },
  },
};
</script>

<style lang="scss">
//   .media {

//     &__content {
//       margin: 20px 0 0;

//       &--list {
//         width: 100%;
//         display: flex;
//         flex-direction: column;

//         ul {
//           display: flex;
//           justify-content: center;

//           li {
//             width: 50%;
//             max-width: 640px;
//             margin: 10px 0;
//             border-bottom: 1px solid $secondary;

//             @media screen and (max-width: 599px) {
//               width: 100%;
//             }

//             a {
//               text-align: left;
//               display: flex;
//               justify-content: flex-start;
//               align-items: center;
//               outline: 0;
//               -moz-outline-style: none;

//               @media screen and (max-width: 599px) {
//                 flex-direction: column;
//               }

//               h3 {
//                 color: $secondary;
//                 font-size: 1.2em;
//                 font-weight: bold;

//                 @media screen and (max-width: 599px) {
//                   font-size: 1em;
//                   text-align: center;
//                 }
//               }
//               img {
//                 width: auto;
//                 height: 180px;
//                 padding: 0 14px 10px 0;
//                 border-radius: 0;

//                 @media screen and (max-width: 599px)  {
//                   padding: 0;
//                   margin: 10px 0 15px;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
</style>
