<template>
  <div class="pt-28 pb-12" style="min-height:80vh;">
    <div class="flex items-center justify-center">
      <select
        @change="langChange($event)"
        class="py-1 px-2 text-center bg-softGrey text-pulpBlue"
      >
        <option selected disabled>Language Categories</option>
        <option v-for="(data, i) in mediaLanguage" :key="i" :value="data.code">
          {{ data.name }}
        </option>
      </select>
    </div>
    <div v-if="getMedia == null">Loading</div>
    <div class="media">
      <ul
        class="md:w-768 lg:w-1024 mt-4 mx-auto flex flex-wrap justify-center items-center"
        v-if="mediaGeneral != null"
      >
        <li
          class="py-1 px-2 mx-1 mt-1 bg-softGrey text-pulpBlue cursor-pointer"
          v-for="(data, i) in mediaGeneral"
          :key="i"
          :class="whichTab == data.category ? 'active' : ''"
          @click="tabContent(data.category)"
        >
          {{ data.category }}
        </li>
      </ul>
      <div class="media__content">
        <MediaGeneralContent
          :mediaGeneral="mediaGeneral"
          :whichTab="whichTab"
          @changed="onChangedChild"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import MediaGeneralContent from "../components/medialibrary/MediaGeneralContent";
import { mapGetters } from "vuex";

export default {
  components: {
    MediaGeneralContent,
  },
  data() {
    return {
      whichTab: "",
      lang: null,
      handleData: false,
      mediaGeneral: null,
      mediaLanguage: null,
      sortedCategories: null,
      selectedLanguage: "English",
      actionLanguage: "ENG",
      showAgile: false,
      myOptions: {
        autoplaySpeed: 3000,
        speed: 2500,
        fade: "fade",
        autoplay: "autoplay",
        centerMode: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              dots: false,
            },
          },
          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              dots: true,
              infinite: false,
            },
          },
        ],
      },
    };
  },
  created() {
    this.actionPostMedia();

    axios({
      method: "GET",
      url: "https://cms.nexgenenglishonline.co/index.php/api/languages",
    })
      .then((res) => {
        // console.log(res);
        this.mediaLanguage = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  mounted() {},
  computed: {
    ...mapGetters("moduleGetMedia", {
      getMedia: "getMedia",
    }),
    handleShowMedia() {
      const data = this.getMedia;

      if (data == false) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    actionPostMedia() {
      const bodyFormData = new FormData();
      bodyFormData.set("role_key", "NG");
      bodyFormData.set("lang", this.actionLanguage);
      bodyFormData.set("type", "neo Corporate Website");
      // bodyFormData.set('code', 'BR,V,SV')

      // this.$store.dispatch('moduleGetMedia/postMediaFiles', bodyFormData)

      // to display data inside slider
      // this.searchCategories("Study Guides", this.getMedia)

      axios({
        method: "POST",
        url: "https://cms.nexgenenglishonline.co/index.php/api/media_files/by_category2",
        data: bodyFormData,
      })
        .then((res) => {
          // console.log(res);
          this.mediaGeneral = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    tabContent(val) {
      this.whichTab = val;
    },
    searchCategories(nameKey, myArray) {
      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i].category === nameKey) {
          setTimeout(() => {
            this.sortedCategories = myArray[i].data_category[0].media_files;
            this.showAgile = true;
          }, 1000);
        }
      }
    },
    langChange(i) {
      // console.log(i.target.value)
      this.actionLanguage = i.target.value;
      this.actionPostMedia();
    },
    onChangedChild(i) {
      // console.log(i);
      this.whichTab = i;
    },
  },
};
</script>

<style>
</style>